import { Component } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router, NavigationEnd } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  templateUrl: './contact.component.html'
})
export class ContactUsComponent 
{
  	constructor(private http: HttpClient, private spinner: NgxSpinnerService, public router: Router, public meta : Meta, public title : Title) 
  	{

			title.setTitle('Contact or Schedule Consultation | Workshop No. 5');
			meta.updateTag({name: 'title', content: 'Contact or Schedule Consultation | Workshop No. 5'});
			meta.updateTag({property: 'og:title', content: "Contact or Schedule Consultation | Workshop No. 5"});

			meta.updateTag({name: 'description', content: 'Talk to us about your residential or commercial project. Schedule a free consultation with our team.'});
			meta.updateTag({property: 'og:description', content: "Talk to us about your residential or commercial project. Schedule a free consultation with our team."});

			meta.updateTag({name: 'robots', content: 'INDEX, FOLLOW'});
			meta.updateTag({name: 'author', content: 'Workshop No5'});
			meta.updateTag({name: 'keywords', content: 'Contact, Architecture Services, Residential, Commercial, Office Space, Austin Architect, Workshop No5'});
			
			meta.updateTag({property: 'og:type', content: "website"});
			meta.updateTag({property: 'og:image', content: "/assets/featured/contact-workshop-no-5.jpg"});

  	}

	showErrorAlert (msg) {
		alert(msg);
	}

	onSubmit(e) 
	{
		if (e && e.preventDefault) e.preventDefault();

		var form = (document.getElementById("contactForm") as HTMLFormElement),
			name =  (document.getElementById("txtName") as HTMLInputElement).value,
			phone = (document.getElementById("txtPhone") as HTMLInputElement).value,
			email = (document.getElementById("txtEmail") as HTMLInputElement).value,
			requestType = (document.getElementById("selectRequestType") as HTMLSelectElement).value,
			howdYouHear = (document.getElementById("txtHowdYouHear") as HTMLInputElement).value,
			description = (document.getElementById("txtDescription") as HTMLInputElement).value,
			whenToBegin = (document.getElementById("txtWhenToBegin") as HTMLInputElement).value,
			workedWithArchitect = (document.getElementById("txtWorkedWithArchitect") as HTMLInputElement).value;

		const emailTestRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

        form.classList.add('was-validated');

		const valid = form.checkValidity();
		if (!valid) {
			if (e && e.stopPropagation) e.stopPropagation();
			alert('Please check the form for errors and try again.');
			return false;
		}

	  	var model = {
		  "yourName": name,
		  "phone": phone,
		  "email": email,
		  "requestType": requestType,
		  "howdYouHear": howdYouHear,
		  "description": description,
		  "whenToBegin": whenToBegin,
		  "workedWithArchitect": workedWithArchitect
		};

		var httpOptions = {
		  headers: new HttpHeaders({
		    'Content-Type':  'application/json',
		  })
		};
		
		var url = 'https://7ghpawkxqc.execute-api.us-east-1.amazonaws.com/default/sendMail';

	    this.http.post(url, model,httpOptions)
	      .subscribe((data: any) => { 
	      	console.log('subscribe', data);
			if (data && data.statusCode === 200) {
				//add an alert element before the form
				var alert = document.createElement('div');
				alert.classList.add('alert', 'alert-success');
				alert.innerHTML = 'Thanks for your message! We will be in touch soon.';
				form.parentNode.insertBefore(alert, form);
				form.remove();
			} else {
				this.showErrorAlert('There was an error submitting your request. Please try again.');
			}
	        //this.router.navigate(['']);
	      });
		console.log('form submitted');

		return false;
	}
}
